import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'

import { AppAlerts } from '../../common'

// Wrap the component in a Container by default
// Some components look better without Container
const WithContainer = ({ withContainer, children }) => {
  return withContainer ? (
    <Container className="my-3">
      <AppAlerts />
      {children}
    </Container>
  ) : children
}

WithContainer.propTypes = {
  withContainer: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

WithContainer.defaultProps = {
  withContainer: true,
}

export default WithContainer
